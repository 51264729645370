import { KiMClient } from "@yoshteq/ti365-ts-sdk";
import { KimNewMail, MailReceiver } from "./KimNewMail";
import { KimMailTemplateService } from "./Template/KimMailTemplateService";
import { QueryParams } from "@/Util/QueryParams";


/**
 * This class handles opening a new Kim Message with a specific Template being applied.
 */
export class KimNewMailTemplate {

    public static readonly PATH_PREFIX = "#kim/new/template/"

    /**
     * Parses Hash of url to use them as a template
     * 
     * Example: `#kim/new/template/${TemplateID}?templateParam1=value1&receiver=someKim@address.ti`
     * 
     * @param kimClient 
     * @returns 
     */
    public static async createViewFromUrl(kimClient: KiMClient) {
        const url = QueryParams.parseHashAsUrl();
        const pathParts = url.pathname.split("/")
        const templateId = pathParts[pathParts.length - 1]
        const paramMap = QueryParams.translateToMap(url);
        return KimNewMailTemplate.createView(kimClient, templateId, paramMap)
    }


    public static async createView(kimClient: KiMClient, templateId: string, parameter: Map<string, string>) {

        const newMail = new KimNewMail();
        const service = new KimMailTemplateService(kimClient.getObjectStorage());

        const receiver = parameter.get("receiver");
        if (receiver && parameter.get("receiver-name") === undefined) {
            parameter.set("receiver-name", await this.findReceiverName(kimClient, receiver));
        }
        const receiverName = parameter.get("receiver-name");

        const template = await service.find(templateId)
        if (template) {
            newMail.subject = service.replaceVariables(template.subject, parameter);
            newMail.mailBody = service.replaceVariables(template.body, parameter);
        }

        newMail.receivers.push({ name: receiverName, mail: receiver } as MailReceiver);

        return newMail;
    }

    private static async findReceiverName(kimClient: KiMClient, address: string): Promise<string> {
        address = address.toLocaleLowerCase()
        const results = await kimClient.searchReceiver(address)
        for (const entry of results) {
            for (const komLeEntry of entry.mail) {
                if (komLeEntry.mail.toLocaleLowerCase() === address) {
                    return entry.displayName;
                }
            }
        }
        return address;
    }
}