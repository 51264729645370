export class CacheValue<T> {

    private value: T | undefined;
    private lastUpdate: number = 0;

    constructor(private timeoutMs: number = 1000, startValue: T | undefined = undefined) {
        this.value = startValue;
    }

    private now() {
        return new Date().getTime();
    }

    public getOrLoad(loadFunction: () => T): T {
        let result = this.get();
        if (result === undefined) {
            result = loadFunction();
            this.set(result);
        }
        return result;
    }

    public async getOrLoadAsync(loadFunction: () => Promise<T>): Promise<T> {
        let result = this.get();
        if (result === undefined) {
            result = await loadFunction();
            this.set(result);
        }
        return result;
    }

    public set(newValue: T) {
        this.lastUpdate = this.now();
        this.value = newValue;
    }

    public invalidateCache() {
        this.lastUpdate = 0;
    }

    public get(): T | undefined {
        if (this.value === undefined) {
            return undefined;
        }
        if (this.now() - this.lastUpdate > this.timeoutMs) {
            this.value = undefined;
            return undefined
        }
        return this.value;
    }
}