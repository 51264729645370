import { watch } from "@lit-labs/preact-signals";
import { DruideTextField } from "@yoshteq/druide-webcomponents";
import { RegistrationService, RegistrationState, Ti365, UserRegistrationInformation } from "@yoshteq/ti365-ts-sdk";
import { html, LitElement, TemplateResult } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import zxcvbn from "zxcvbn";
import { portalName } from "./BrandingSettings";
import { CssConst } from "./Components/CssConst";
import { StatusEventController } from "./Components/StatusEventController";
import { svgLoadingSpinner } from "./Components/SvgConst";


export type OnboardingState = "unknown" | RegistrationState;
@customElement("ti-onboarding")
export class Onboarding extends LitElement {

    onboardingState: OnboardingState = "unknown";

    status = new StatusEventController(this);
    keyPair!: Promise<CryptoKeyPair>;

    @query("#password")
    password!: DruideTextField;

    @query("#passwordRepeat")
    passwordRepeat!: DruideTextField;

    @query("#sms-pin")
    pinField!: DruideTextField;

    @state()
    pendingAction = false;

    mailAddress?: string;

    private registrationService: RegistrationService;
    private userInformation?: UserRegistrationInformation;

    constructor(jwt: string) {
        super();
        this.registrationService = Ti365.get().createRegistrationService(jwt);
    }

    override connectedCallback(): void {
        super.connectedCallback();
        this.loadUserData();
    }

    private async loadUserData() {
        this.userInformation = await this.registrationService.getUserState();
        if (this.userInformation.state === "USER_READY") {
            location.hash = "";
            return;
        } else {
            this.onboardingState = this.userInformation.state;
        }

        this.requestUpdate();
    }

    protected override render(): TemplateResult {
        return html`
            <header-layout>
                <div slot="header-center">${watch(portalName)}</div>

                  ${this.renderContent()}
                </div>
            </header-layout>
            
           `;
    }


    private async addDevice() {
        this.mailAddress = this.shadowRoot!.querySelector<HTMLInputElement>("#mail-address")!.value;
        const phoneNumber = this.shadowRoot!.querySelector<HTMLInputElement>("#mobile-phone-number")!.value;

        this.userInformation!.mailAddress = this.mailAddress;
        this.userInformation!.mobilePhoneNumber = phoneNumber;

        await this.registrationService.setMfaPhoneNumber(phoneNumber);

        this.onboardingState = "PASSWORD_MISSING";
        this.requestUpdate();
    }

    private async saveKey() {

        const pw = this.password.value;
        if (this.passwordScore < 3) {
            this.password.error = `Die Passwortstärke muss 3 oder höher betragen (ist: ${this.passwordScore})`;
            this.password.focus();
            return;
        } else {
            this.password.error = undefined;
        }

        const pwR = this.passwordRepeat.value;
        if (pw !== pwR) {
            this.passwordRepeat.error = "Die Passwörter sind nicht gleich.";
            this.passwordRepeat.focus();
            return;
        }

        try {
            this.pendingAction = true;
            const response = await this.registrationService.registerUser(this.userInformation!, pw, this.pinField.value);
            if (response.code !== "success") {
                this.pinField.error = response.message;
                return;
            }
        } finally {
            this.pendingAction = false;
        }

        window.location.reload();
    }

    private gotoToPhoneInput() {
        this.onboardingState = "PHONE_NUMBER_MISSING";
        this.requestUpdate();
    }

    private renderContent(): TemplateResult {
        if (this.onboardingState === "unknown") {
            return html`
            <div slot="content" style=${CssConst.flexCenter}>
                <druide-card slot="content" style="max-width:400px;min-width:300px;" label="Herzlich Willkommen im Telematik Portal">
                <loader-message>${this.status.message}</loader-message>
                </druide-card>
            </div>
            `;
        } else if (this.onboardingState === "PHONE_NUMBER_MISSING" && this.userInformation) {
            return this.renderPhoneInput();
        }
        else if (this.onboardingState === "PASSWORD_MISSING" && this.userInformation) {
            return this.renderPasswordInput();
        }
        else if (this.onboardingState === "PASSWORD_SET_NO_SERVICE") {
            return html`               
            <div slot="content" style=${CssConst.flexCenter}>
                <druide-card slot="content" style="max-width:400px;min-width:300px;" label="Herzlich Willkommen im Telematik Portal">     
                    <div style="display:flex; flex-direction:column">
                        <div style="text-align:justify">
                            <br>
                            Ihre Anmeldung ist abgeschlossen. <br><br>
                            Damit Sie die TI benutzen können, müssen Ihre Zugänge zur eingerichtet werden. 
                            Da dies ein händischer Prozess ist, kann dies ein paar Tage dauern.
                            Bitte haben Sie so lange Geduld.
                        </div>    
                    </div>
                </druide-card>
            </div>
            `;
        }
        console.error("Unknown onboarding state: ", this.onboardingState);
        return html`Unbekannter Status: ${this.onboardingState}`;
    }

    @state()
    private passwordScore = 0;

    private renderPasswordInput(): TemplateResult {
        return html`    
                <div slot="content" style=${CssConst.flexCenter} @submit=${this.saveKey}>
                    <druide-card slot="content" style="max-width:600px;min-width:300px;" label="Herzlich Willkommen im Telematik Portal">               
                        <div style="display:flex; flex-direction:column">
                            <div style="text-align:justify">
                                Zur Prüfung ihrer Handynummer ( ${this.userInformation!.mobilePhoneNumber} ) haben wir Ihnen eine SMS mit einem Pin geschickt. 
                                Bitte geben Sie diesen Code hier ein:
                            </div>
                            <druide-text-field id="sms-pin" label="SMS-Pin:" value="" type="tel" required autofocus></druide-text-field>
                            <div style="text-align:justify">
                                Bitte vergeben Sie sich noch ein Passwort welches Ihren TI Zugang absichert:
                            </div>
                            <druide-text-field id="password" label="Passwort:" value="" type="password" required @change=${this.calculatePasswordScore}></druide-text-field>
                            <druide-text-field id="passwordRepeat" label="Passwort Wiederholen:" value="" type="password" required></druide-text-field>
                            <div style="font-weight:700">Passwortstärke: <span style="color: ${this.getPasswordScoreColor()}">${this.passwordScore}/4</span></div>
                            <div style="font-size:0.85em">Für die Nutzung von Ti365 wird ein sicheres Passwort benötigt (minimale Passwortstärke 3/4). Wir empfehlen die Verwendung eines Passwortmanagers in Betracht zu ziehen.</div>
                        </div>
                        <div slot="footer-right">
                            ${this.pendingAction ? svgLoadingSpinner : html``} 
                            <druide-button  title="TI-Account erstellen" .disabled=${this.pendingAction}  submit></druide-button>
                        </div>
                        <druide-button slot="footer-left" title="zurück"  @click=${this.gotoToPhoneInput}></druide-button>
                    </druide-card>
                </div>
            `;
    }

    private calculatePasswordScore() {
        setTimeout(() => {
            // https://www.usenix.org/conference/usenixsecurity16/technical-sessions/presentation/wheeler
            const result = zxcvbn(this.password.value, ["ti", "365", "miya", "yoshteq", this.userInformation?.firstname, this.userInformation?.lastname]);
            this.passwordScore = result.score;
            this.requestUpdate();
        }, 0);
    }

    private getPasswordScoreColor() {
        switch (this.passwordScore) {
            case 2:
            case 3:
                return "orange";
            case 4:
                return "green";
            default:
                return "red";
        }
    }

    private renderPhoneInput(): TemplateResult {
        return html`
                <div slot="content" style=${CssConst.flexCenter} @submit=${this.addDevice}>
                    <druide-card slot="content" style="max-width:400px;min-width:300px;" label="Herzlich Willkommen im Telematik Portal">               
                        <div style="display:flex; flex-direction:column">
                            <div style="text-align:justify">
                                Hallo ${this.userInformation!.firstname} ${this.userInformation!.lastname},<br>
                                damit Sie die Telematik Infrastruktur ( TI ) nutzen können, 
                                benötigen wir noch einige weitere Informationen von Ihnen.
                            </div>
                            <druide-text-field id="mail-address" label="E-Mail Adresse:" value="${this.userInformation!.mailAddress}"  maxLength="255" type="email" required></druide-text-field>
                            <div style="text-align:justify">
                                Ihre Telefonnummer wird benötigt um eine besondere Sicherheit für Ihr TI-Konto zu gewährleisten. 
                                Wir werden Ihnen eine SMS mit eine Freischalt-Pin auf Ihr Handy schicken.
                            </div>
                            <druide-text-field id="mobile-phone-number" label="Handy Nummer:" value="${this.userInformation!.mobilePhoneNumber}" maxLength="255" type="tel" required></druide-text-field>
                        </div>
                        <druide-button slot="footer-right" title="Weiter"  submit></druide-button>
                    </druide-card>
                </div>
            `;
    }
}
