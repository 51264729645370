import { portalName } from "@/BrandingSettings";
import { TiPortal } from "@/TiPortal";
import { watch } from "@lit-labs/preact-signals";
import { DruideTextField, SuggestOption } from "@yoshteq/druide-webcomponents";
import { CacheService, RecordIdentifier, StatusEvent } from "@yoshteq/ti365-ts-sdk";
import { LitElement, TemplateResult, html } from "lit";
import { customElement, query } from "lit/decorators.js";
import { CssConst } from "../Components/CssConst";
import { StatusEventController } from "../Components/StatusEventController";
import { svgBackArrow } from "../Components/SvgConst";

@customElement("epa-select-view")
export class EpaSelectView extends LitElement {
    status = new StatusEventController(this);

    @query("#insurance-number")
    insurantInput!: DruideTextField;
    searchPending: boolean = false;

    constructor(private searchId?: string) {
        super();
    }

    override connectedCallback(): void {
        super.connectedCallback();
    }

    private async startSearch() {
        if (this.searchPending) {
            return;
        }
        try {

            const insurantNumber = this.insurantInput.value;
            this.searchPending = true;
            this.requestUpdate();

            if (!insurantNumber.match(/^[A-Z]\d{8}\d$/)) {
                this.insurantInput.error = "Bitte eine gültige 10 stellige Versichertennummer eingeben.";
                return;
            } else {
                this.insurantInput.error = undefined;
            }

            let recordIdentifier: RecordIdentifier;
            const cacheResult = CacheService.get("HomeCommunityId", this.insurantInput.value);
            if (cacheResult) {
                return new RecordIdentifier(this.insurantInput.value, cacheResult);
            } else {
                StatusEvent.log("Bestimme ePA Anbieter anhand der Versichertennummer.");

                const connector = await TiPortal.tiSession.getConnector();
                recordIdentifier = await connector.getPhrManagementService().getHomeCommunityID(this.insurantInput.value);
                if (!recordIdentifier) {
                    return;
                }
            }
            this.dispatchEvent(new CustomEvent("record-identifier", { bubbles: true, composed: true, detail: recordIdentifier }));
            location.hash = "#epa";


        } catch (e) {
            StatusEvent.log("Fehler beim laden der Daten aus der TI");
            throw e;
        } finally {
            this.searchPending = false;
            this.requestUpdate();
        }
    }

    autocomplete(e: KeyboardEvent): void {
        if (["Down", "ArrowDown", "Up", "ArrowUp"].indexOf(e.key) != -1) {
            return;
        }
        let searchStr = this.insurantInput.value.toUpperCase();
        let values = ["X110497031", "X110444155"];
        values = values.filter(v => v.startsWith(searchStr));
        this.insurantInput.suggestOptions = values.map(s => new SuggestOption(s, s));
    }

    protected override render(): TemplateResult {
        return html`
            <header-layout>
                <div slot="header-left"><druide-icon-button id="app-button" class="back-header" @click=${() => history.back()}>${svgBackArrow}</druide-icon-button></div>
                <div slot="header-center">${watch(portalName)}</div>
                <div slot="content" .style=${CssConst.flexCenter}>
                    <druide-card slot="content" style="width:360px;" label="ePA Suche">
                        <div style="display:flex; flex-direction:column">
                            <div>Suche nach elektronischer Patienten Akte einer Versicherten:</div>
                            
                            <druide-text-field id="insurance-number"
                                label="Versichertennummer:" 
                                @keyup=${this.autocomplete}
                                @suggest-select=${(e) => { this.insurantInput.value = e.detail.id; this.insurantInput.suggestOptions = undefined; }}
                                required 
                                autofocus 
                                .disabled=${this.searchPending}>
                            </druide-text-field>
                ${this.searchPending ?
                html`<loader-spinner style="align-self:center"></loader-spinner>`
                : html``}
                            <div>${this.status.message}</div>
                        </div>
                        <druide-button slot="footer-right" title="Suche starten" @click=${this.startSearch} .disabled=${this.searchPending}></druide-button>
                    </druide-card>
                </div>
            </header-layout>`;
    }

}
