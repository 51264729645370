import { TiPortal } from "@/TiPortal";
import "@tinymce/tinymce-webcomponent";
import { DruideChipField, DruideModal, DruideTextField, DruideToast, SuggestOption, WarningLevel } from "@yoshteq/druide-webcomponents";
import {
    ContentTypeHeader,
    KasClientErrorDetails,
    KiMClient,
    KimClientError,
    KimEvent,
    KimStoredMailAttachmentInfo,
    Mail,
    MailAddressHeader,
    MailBodyPart,
    MailHeader,
    MailHeaders,
    RecipientErrorDetails,
    RecipientsErrorDetails,
    StatusEvent
} from "@yoshteq/ti365-ts-sdk";
import { Base64 } from "js-base64";
import { LitElement, PropertyValueMap, TemplateResult, html, render, unsafeCSS } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { DruideDragDropUpload, UploadInfo, UploadInfoPending, UploadInfoSuccess } from "../Components/DruideDragDropUpload";
import { StatusEventController } from "../Components/StatusEventController";
import { svgAttachment, svgCheck, svgDelete, svgKimTemplate, svgWarning } from "../Components/SvgConst";
import { byteToHuman, downloadBlob } from "../Functions";
import style from "./KimNewMail.css?inline";
import { KimMailTemplateService } from "./Template/KimMailTemplateService";
import { KimMailTemplateType } from "./Template/KimMailTemplateType";
import { KimTemplateListEntry } from "./Template/KimTemplateListEntry";
import KimTemplateSelectDialog from "./Template/KimTemplateSelectDialog";

interface KimStoredMailAttachment extends KimStoredMailAttachmentInfo {
    data: Uint8Array;
}

export type MailReceiver = { name: string; mail: string };

class TemplateOption extends SuggestOption {
    constructor(public template: KimMailTemplateType) {
        super(template.id || "", new KimTemplateListEntry(template) as any);
    }
}

@customElement("kim-new-mail")
export class KimNewMail extends LitElement {
    static styles = [unsafeCSS(style)];

    @state()
    private attachments: KimStoredMailAttachment[] = [];
    private uploadInfo?: UploadInfo;
    private status = new StatusEventController(this);
    private state: "sending" | "open" | "success" | "error" = "open";
    private kimClient?: KiMClient;

    @query("druide-drag-drop-upload")
    upload!: DruideDragDropUpload;
    @query("kim-template-select-dialog")
    kimTemplateDialog!: KimTemplateSelectDialog;
    @query("#subject-input")
    subjectInput!: DruideTextField;
    @query("#to-input")
    toInput!: DruideChipField<MailReceiver>;
    @query("#content-input")
    contentInput!: { value: string } & HTMLElement;
    @query("#error-container")
    errorContainer!: HTMLDivElement;
    @query("druide-modal")
    dialog!: DruideModal;

    @property()
    title: string = "Neue KIM Nachricht";

    @property({ attribute: false })
    receivers: MailReceiver[] = [];

    @property({ attribute: false })
    headers: MailHeader[] = [];

    @property()
    subject: string = "";

    @property()
    mailBody: string = "";

    constructor() {
        super();
    }

    private receiveFile(file: UploadInfo) {
        if (file.status === "pending") {
            this.uploadInfo = file;
        } else if (file.status === "success") {
            this.uploadInfo = undefined;
            const uploadInfo = file as UploadInfoSuccess;
            this.attachments.push({
                title: uploadInfo.info.name,
                mimeType: uploadInfo.info.type,
                size: uploadInfo.info.size,
                data: uploadInfo.data,
                contentId: crypto.randomUUID(),
                storageId: crypto.randomUUID(),
            } as KimStoredMailAttachment);
        } else {
            this.uploadInfo = file;
        }
        this.requestUpdate();
    }

    protected async sendMail() {
        this.requestUpdate();

        const subject = this.subjectInput.value;
        if (!subject || subject.length === 0) {
            this.subjectInput.error = "Bitte einen Betreff angeben";
            return;
        } else {
            this.subjectInput.error = undefined;
        }

        // const receivers = this.toInput.selectedOptions;

        if (this.receivers.length === 0) {
            this.toInput.error = "Bitte einen Empfänger angeben";
            return;
        } else {
            this.toInput.error = undefined;
        }

        const contentText = this.contentInput.value;

        this.state = "sending";

        try {
            const kimClient = await this.getKimClient();
            KimEvent.log("Nachricht wird vorbereitet");
            const htmlPart = MailBodyPart.createHtmlPart(contentText);
            const attachmentParts = this.attachments.map((a) => MailBodyPart.createFilePart(a.title, a.mimeType, Base64.fromUint8Array(new Uint8Array(a.data))));
            const headers = new MailHeaders();
            headers.add(new MailAddressHeader("To", this.receivers.map((r) => r.mail).join(", ")));
            headers.add(new MailHeader("Date", new Date().toUTCString()));
            headers.add(new MailHeader("Subject", subject));

            this.headers.forEach(h => headers.add(h));

            headers.add(new MailHeader("MIME-Version", "1.0"));
            headers.add(new ContentTypeHeader("multipart/mixed"));
            const mail = new Mail(headers, [htmlPart, ...attachmentParts], undefined);

            await kimClient.sendMail(mail);
            this.state = "success";
            this.dispatchEvent(new CustomEvent("sent-mail", { bubbles: true, composed: true, detail: { mail } }));
            this.requestUpdate();
        } catch (e) {
            this.dialog.hide();
            this.state = "open";
            if (e instanceof KimClientError) {
                if (e.code === "RECIPIENTS_ERROR") {
                    this.handleRecipientsError(e.detail as RecipientsErrorDetails);
                } else if (e.code === "MAIL_FORMAT_ERROR") {
                    this.dispatchEvent(new CustomEvent("postToast", { detail: { warningLevel: WarningLevel.ERROR, text: "Nachricht ist zu groß für die Accounteinstellungen" } }));
                } else if (e.code === "KAS_CLIENT_ERROR") {
                    this.handleKasClientError(e.detail as KasClientErrorDetails);
                } else {
                    this.dispatchEvent(
                        new CustomEvent("postToast", { detail: { warningLevel: WarningLevel.ERROR, text: "Es ist ein Fehler beim Versenden von Nachrichten aufgetreten" } }),
                    );
                }
            }
            StatusEvent.log("Fehler beim senden der Nachricht: " + (e as Error).message);
            this.requestUpdate();
        }
    }

    private handleRecipientsError(detail: RecipientsErrorDetails) {
        render(
            html`
                <div class="dialog">
                    <h3>Die Mail konnte nicht versendet werden da:</h3>
                    ${detail.recipientErrors.map(this.getRecipientErrorMessage.bind(this))}
                    <div class="dialog-buttons">
                        <druide-button @click=${() => this.onCancelClicked()}>Abbrechen</druide-button>
                        <druide-button @click=${() => this.onRemoveErrorRecipientsClicked(detail)}>Empfänger entfernen</druide-button>
                    </div>
                </div>
            `,
            this.dialog,
        );
        this.dialog.show();
    }

    private getRecipientErrorMessage(recipientDetail: RecipientErrorDetails) {
        switch (recipientDetail.errorCode) {
            case "RECIPIENT_NOT_FOUND":
                return html`<div>• Der Empfänger ${recipientDetail.recipientAddress} nicht im KIM Adressbuch gefunden werden kann. </div>`;
            case "RECIPIENT_NO_CERTIFICATE":
                return html`<div>• Sie diese Nachricht nicht an ${recipientDetail.recipientAddress} senden können, da der Empfänger kein kein Zertifikat hinterlegt hat. </div>`;
            case "RECIPIENT_NO_KAS_ALLOWED":
                return html`<div>• Sie diese Nachricht nicht an ${recipientDetail.recipientAddress} senden können, da dieser Empfänger nur Nachrichten bis zu einer Größe von 15 MB unterstützt. </div>`;
            case "RECIPIENT_ADDRESS_ENTRY_ERROR":
                return html`<div>• Sie diese Nachricht nicht an ${recipientDetail.recipientAddress} senden können, da dieser Empfänger einen fehlerhaften Datensatz im Adressbuch von KIM hat und keine eindeutige Telematik-ID mit Verschlüsselungszertifikat gefunden wurde. </div>`;
        }
    }

    private onCancelClicked() {
        this.dialog.removeChild(this.dialog.querySelector(".dialog")!);
        this.dialog.hide();
    }

    private onRemoveErrorRecipientsClicked(detail: RecipientsErrorDetails) {
        this.removeErrorRecipients(detail.recipientErrors.map((e) => e.recipientAddress));
        this.dialog.removeChild(this.dialog.querySelector(".dialog")!);
        this.dialog.hide();
    }

    private removeErrorRecipients(addresses: string[]) {
        this.receivers = this.toInput.selectedOptions.filter((option) => {
            if (addresses.includes(option.mail)) {
                return false;
            }
            return true;
        });
    }

    private handleKasClientError(detail: KasClientErrorDetails) {
        const errorToast = new DruideToast();
        errorToast.warningLevel = WarningLevel.ERROR;
        errorToast.text = "Es ist ein Fehler beim Versenden von Nachrichten aufgetreten";
    }

    private async getKimClient(): Promise<KiMClient> {
        if (!this.kimClient) {
            this.kimClient = await TiPortal.kimClient;
            if (!this.kimClient) {
                throw new Error("KiM not initialized");
            }
        }
        return this.kimClient;
    }

    private async searchReceiver(searchText: string): Promise<MailReceiver[]> {
        const result = (await (await this.getKimClient()).searchReceiver(searchText)).flatMap((entry) => {
            const receivers: MailReceiver[] = [];
            entry.mail.forEach((komLeEntry) => {
                if (
                    entry.displayName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
                    komLeEntry.mail.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                ) {
                    receivers.push({ name: entry.displayName, mail: komLeEntry.mail } as MailReceiver);
                }
            });
            return receivers;
        });
        this.lastSearchQuery = searchText.toLocaleLowerCase();
        return result;
    }

    private lastSearchQuery?: string;
    private generateMailAddressSuggest = (receiver: MailReceiver): TemplateResult => {
        if (this.lastSearchQuery) {
            const escapedQuery = this.lastSearchQuery.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");
            const regexp = new RegExp("(" + escapedQuery + ")", "ig");
            const mail = this.escapeHtml(receiver.mail).replaceAll(regexp, `<span style="background:#fefebc;">$1</span>`);
            const name = this.escapeHtml(receiver.name).replaceAll(regexp, `<span style="background:#fefebc;">$1</span>`);

            return html`${unsafeHTML(name)} &lt;${unsafeHTML(mail)}&gt;`;
        }
        return html`${receiver.name} &lt; ${receiver.mail} &gt;`;
    };

    private escapeHtml(unsafe: string): string {
        return unsafe.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
    }

    private templateService: KimMailTemplateService | undefined;
    private async getTemplateService(): Promise<KimMailTemplateService> {
        if (this.templateService === undefined) {
            const client = await this.getKimClient();
            const storage = client.getObjectStorage();
            this.templateService = new KimMailTemplateService(storage);
        }
        return this.templateService;
    }

    private async suggestTemplate() {
        const templateService = await this.getTemplateService();
        const templates = await templateService.searchTemplates(this.subjectInput.value);
        this.subjectInput.suggestOptions = templates.map(t => new TemplateOption(t));
    }

    private async templateOptionSelected(selectedOption: TemplateOption): Promise<void> {
        await this.templateSelected(selectedOption.template);
    }

    private async templateSelected(template: KimMailTemplateType): Promise<void> {
        const service = await this.getTemplateService();
        const paramMap = new Map<string, string>();
        if (this.receivers.length > 0) {
            paramMap.set("empfaenger.name", this.receivers[0].name);
            paramMap.set("empfaenger.email", this.receivers[0].mail);
        }
        this.subjectInput.suggestOptions = [];
        this.subjectInput.value = service.replaceVariables(template.subject, paramMap);
        this.contentInput.value = service.replaceVariables(template.body, paramMap);
    }

    protected override render(): TemplateResult {
        return html`
            <druide-card label=${this.title} @submit=${this.sendMail}>
                <div slot="header-action">
                    <druide-icon-button title="Vorlagen" @click=${() => this.showTemplateDialog()}>${svgKimTemplate}</druide-icon-button>
                    <druide-icon-button title="Anhang hinzufügen" @click=${() => this.upload.openUploadFileDialog()}>${svgAttachment}</druide-icon-button>
                </div>
                <div class="main-layout">
                    <druide-chip-field id="to-input" label="An:" required 
                        .selectedOptions=${this.receivers} 
                        .optionsSearch=${(s) => this.searchReceiver(s)} 
                        .optionToSuggest=${this.generateMailAddressSuggest} 
                        .optionToChip=${(s) => s.name} multiline>
                    </druide-chip-field>
                    <druide-text-field id="subject-input" label="Betreff:"
                        @suggest-select=${(e) => this.templateOptionSelected((e as CustomEvent).detail as TemplateOption)} 
                        @change=${this.suggestTemplate} .value=${this.subject} required></druide-text-field>
                    ${this.buildEditor()}
                    <div class="attachments">
                        ${this.attachments.map((e) => this.renderAttachment(e))}
                        ${this.renderUploadingAttachment()}
                    </div>
                </div>
                <div slot="footer-left" style="align-self:center; font-size:0.85em;">${this.status.message}</div>
                <druide-button slot="footer-right"  title="Nachricht senden"  submit>Senden</druide-button>
            </druide-card>
            <druide-drag-drop-upload @file-upload=${(e) => this.receiveFile(e.detail)}></druide-drag-drop-upload>
            <druide-modal ?visible=${false} close-explicit>
                ${this.renderSendingDialog()}
            </druide-modal>
            <kim-template-select-dialog></kim-template-select-dialog>
        `;
    }

    private async showTemplateDialog() {
        this.kimTemplateDialog.show(await this.getTemplateService(), (t) => this.templateSelected(t));
    }

    private buildEditor(): TemplateResult {
        return html`
            <tinymce-editor id="content-input"
            plugins="preview importcss searchreplace autolink visualblocks visualchars fullscreen image link media table charmap nonbreaking anchor insertdatetime advlist lists charmap quickbars emoticons accordion"
            toolbar="undo redo | bold italic underline strikethrough | blocks | alignleft aligncenter alignright alignjustify | outdent indent | fontfamily fontsize | numlist bullist accordion | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview | insertfile image media template link anchor codesample"
            menubar="edit insert view format table tools"
            removed_menuitems="styles"
                promotion="false"
                language_url="/de.js"
                language="de">
                ${this.mailBody}
            </tinymce-editor>
            `;
    }

    protected backToOverview() {
        history.back();
    }

    protected override firstUpdated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
        super.firstUpdated(_changedProperties);
        if (this.mailBody.length > 0) {
            window.setTimeout(() => {
                this.contentInput.focus();
            }, 100);
        }
    }


    protected renderSendingDialog(): TemplateResult<1> | undefined {
        if (this.state === "sending" || this.state === "success") {
            this.dialog.show();
            return html`
                <div class="dialog">
                    ${this.renderSendingDialogContent()}
                </div>
                `;
        }
    }

    private renderSendingDialogContent() {
        if (this.state === "sending") {
            return html`<loader-message>${this.status.message}</loader-message>`;
        } else if (this.state === "success") {
            return html`
                <div class="sending-success-content">
                    <div class="sending-success-text">
                        <druide-icon .svg=${svgCheck} class="sending-success-icon"></druide-icon>
                        <h3>${this.status.message}</h3>
                    </div>
                    <druide-button @click=${() => this.backToOverview()}>Zur Übersicht</druide-button>
                </div>`;
        } else {
            return undefined;
        }
    }

    private downloadAttachment(attachment: KimStoredMailAttachment) {
        downloadBlob(new Blob([attachment.data], { type: attachment.mimeType }));
    }
    private deleteAttachment(attachment: KimStoredMailAttachment) {
        const idx = this.attachments.indexOf(attachment);
        this.attachments.splice(idx);
        this.requestUpdate();
    }

    protected renderAttachment(mailAttachment: KimStoredMailAttachment) {
        return html`
      <div class="attachment" @click=${() => {
                this.downloadAttachment(mailAttachment);
            }}>
        <div class="icon">${svgAttachment} </div>
        <div class="name">${mailAttachment.title}</div>
        <div class="size">${byteToHuman(mailAttachment.size)} </div>
        <druide-icon-button class="action"  @click=${(e) => {
                this.deleteAttachment(mailAttachment);
                e.stopPropagation();
            }} >${svgDelete}</druide-icon-button>
      </div>`;
    }

    private renderUploadingAttachment(): TemplateResult | undefined {
        if (this.uploadInfo) {
            return html`
                <div class="attachment">
                    <div class="icon">${this.uploadInfo.status === "pending" ? svgAttachment : svgWarning}</div>
                    <div class="name">${this.uploadInfo.info.name}</div>
                    <div class="size">${byteToHuman(this.uploadInfo.info.size)}</div>
                    <druide-icon-button class="action">${svgDelete}</druide-icon-button>
                    ${this.renderProgressBar()}
                </div>
            `;
        }
        return undefined;
    }

    private renderProgressBar() {
        if (this.uploadInfo?.status !== "pending") {
            return undefined;
        }
        const info = this.uploadInfo as UploadInfoPending;
        return html`
            <div class="upload-progress" style="width: ${info.uploadPercentage}%"></div>
        `;
    }
}
