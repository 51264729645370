import { svgAdd, svgClose, svgMenu } from "@/Components/SvgConst";
import { DruideModal, DruideTextField } from "@yoshteq/druide-webcomponents";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, query } from "lit/decorators.js";
import { KimMailTemplateService } from "./KimMailTemplateService";
import { KimMailTemplateType } from "./KimMailTemplateType";
import { KimTemplateEditView } from "./KimTemplateEditView";
import { KimTemplateListEntry } from "./KimTemplateListEntry";

@customElement("kim-template-select-dialog")
export default class KimTemplateSelectDialog extends LitElement {
    static styles = css`

    druide-modal{
        max-width: 100%;
        max-height:100%;
    }

    druide-card{
        max-width: 600px;
        width: 90%;
    }

    .list{
        border-radius: var(--card-radius, 1.5rem);
        border: 1px solid var(--input-disabled-color);
        height:200px;
        overflow:auto;
        
    }

    .list > * {
        border-top: 1px solid var(--input-disabled-color);
    }

    .list > *:first-child {
        border-top: 0;
    }

    .list-entry{
        padding:0.5rem;
        display: flex; 
        flex-direction:row;
        align-items:center; 
        justify-content:space-between;
    }
    `;

    @query("druide-modal")
    modal!: DruideModal;
    @query("#searchField")
    searchField!: DruideTextField;

    private templates: KimMailTemplateType[] = [];
    private templateService?: KimMailTemplateService;
    private applyTemplateAction?: (KimMailTemplateType: any) => unknown;

    constructor() {
        super();
    }


    protected render(): TemplateResult {
        return html`
            <druide-modal>
                <druide-card label="Vorlagen">
                    <druide-icon-button slot="header-action" @click=${this.hide}>${svgClose}</druide-icon-button>
                    <div>
                        <div style="display: flex; flex-direction:row;align-items:center;justify-content:space-between;">
                        <druide-text-field label="Suche" id="searchField" @change=${() => this.searchTemplates()}></druide-text-field>
                        <druide-icon-button @click=${this.createNew}>${svgAdd}</druide-icon-button>
                        </div>
                        <div class="list">
                            ${this.templates.map(t => this.renderTemplateEntry(t))}
                            ${this.templates.length === 0 ? html`<div class="list-entry">Keine Vorlagen gefunden. Mit dem Plus Symbol können sie gerne eine Vorlage neu anlegen.</div>` : html``}
                        </div>
                    </div>
                </druide-card>
            </druide-modal>
        `;
    }

    private renderTemplateEntry(template: KimMailTemplateType): TemplateResult | Element {
        const entry = new KimTemplateListEntry(template);
        return html`
        <div class="list-entry">
            ${entry}
            <druide-dropdown-menu > 
                <druide-icon-button slot="trigger">${svgMenu}</druide-icon-button>
                <druide-menu-item @click=${() => this.applyTemplate(template)}>Verwenden</druide-menu-item>
                <druide-menu-item @click=${() => KimTemplateEditView.navigateTo(template.id)} >${template.userDefined ? 'Bearbeiten' : 'Duplizieren'}</druide-menu-item>
            </druide-dropdown-menu>
        </div>
        `
    }

    private applyTemplate(template: KimMailTemplateType) {
        if (this.applyTemplateAction) {
            this.applyTemplateAction(template);
        }
        this.hide();
    }

    private createNew() {
        KimTemplateEditView.navigateTo(undefined);
    }

    public show(templateService: KimMailTemplateService, applyTemplateAction: (KimMailTemplateType) => unknown) {
        this.applyTemplateAction = applyTemplateAction;
        this.templateService = templateService;
        this.modal.show();
        this.searchTemplates();
    }
    private async searchTemplates() {
        if (this.templateService) {
            this.templates = await this.templateService.searchTemplates(this.searchField.value)
            this.requestUpdate();
        }
    }

    public hide() {
        this.modal.hide();
    }
}