
export class QueryParams {

    public static parseHashAsUrl(): URL {
        const url = new URL("/" + location.hash.substr(1), location.origin)
        return url;
    }

    public static translateToMap(url: URL): Map<string, string> {

        const map = new Map<string, string>();
        url.searchParams.forEach((value, key) => {
            map.set(key, value);
        })
        return map;
    }

}

