import { watch } from "@lit-labs/preact-signals";
import { css, html, LitElement, TemplateResult } from "lit";
import { customElement } from "lit/decorators.js";
import { portalName } from "./BrandingSettings";
import { CssConst } from "./Components/CssConst";
import { svgCardDown, svgEpa, svgKim } from "./Components/SvgConst";

@customElement("ti-service-select")
export class TiServiceSelect extends LitElement {
    static styles = css`
    .card-container{
        display:flex;
        flex-wrap:wrap;
        justify-content:center;
        
    }
    .card {
        margin:10px;
        height:100px;
        width:100px;
        background-color: #fff;
        transition: 0.25s;
        border-radius: var(--card-radius, 1.5rem);
        overflow: hidden;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        padding: 12px 16px 8px;
        cursor:pointer;
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction:column;
    }
    .card > p{
        font-size:1.5em;
        margin:0;
        margin-bottom:10px;
    }
    h2{
        margin-left:10px;
    }
    .card:hover{
        --primary-color: var(--text-color-hover);
        background: var(--background-color-hover);
        color: var(--text-color-hover);
        box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.20), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); 
    }
    `;
    protected override render(): TemplateResult {
        return html`
            <header-layout>
                <div slot="header-left"><img src="/branding/logo"/></div>
                <div slot="header-center">${watch(portalName)}</div>
                <div slot="header-right"><logout-button></logout-button></div>
                <div slot="content" .style=${CssConst.flexCenter}>
                    <div>
                        <h2>Apps:</h2>
                        <div class="card-container">
                            <div slot="content" class="card" @click=${() => location.hash = "#vzd"}  >
                                ${svgCardDown}
                                <p>VZD</p>
                            </div>
                            <div slot="content" class="card" @click=${() => location.hash = "#egk-info"}  >
                                ${svgCardDown}
                                <p>VSDM</p>
                            </div>
                            <div slot="content" class="card" @click=${() => location.hash = "#kim"} >
                                ${svgKim}
                                <p>KIM</p>
                            </div>
                            <div slot="content" class="card" @click=${() => location.hash = "#epa-select"}  >
                                ${svgEpa}
                                <p>ePA</p>
                            </div>
                        </div>
                    </div>
                </div>
            </header-layout>`;
    }

}
