import "@yoshteq/druide-webcomponents";
import "@yoshteq/druide-webcomponents/style";
import { BackendState, JsonSerializer, KiMClient, RecordIdentifier, StatusEvent, Ti365, Ti365Session, Ti365SessionRuntimeSettings, UserSettings } from "@yoshteq/ti365-ts-sdk";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { CssConst } from "./Components/CssConst";
import DruideDialog from "./Components/DruideDialog";
import "./Components/HeaderLayout";
import "./Components/LoaderMessage";
import "./Components/LoaderSpinner";
import "./Components/LogoutButton";
import "./Components/MasterDetailLayout";
import { EgkInfoView } from "./EgkInfoView";
import { EpaOverview } from "./Epa/EpaOverview";
import { EpaSelectView } from "./Epa/EpaSelectView";
import { KimDeregisterSuccess } from "./Kim/KimDeregisterSuccess";
import { KimOverview } from "./Kim/KimOverview";
import "./Kim/Template/KimTemplateSelectDialog";
import { KimSetupWizardView } from "./Kim/KimSetupWizardView";
import { LoginView } from "./LoginView";
import { Onboarding } from "./Onboarding";
import { TiConnectDialog } from "./TiConnectDialog";
import { TiServiceSelect } from "./TiServiceSelect";
import { VzdClientView } from "./Vzd/VzdClientView";
import { BrandingSettings, BrandingSettingsType, portalName } from "./BrandingSettings";
import { Global } from "./Global";

@customElement("ti-portal")
export class TiPortal extends LitElement {
    static styles = css`
        .dialog-buttons {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
        }
    `;

    private static _tiSession?: Ti365Session;
    public static set tiSession(session: Ti365Session) {
        this._tiSession = session;
    }
    public static get tiSession(): Ti365Session {
        if (!this._tiSession) {
            throw new Error("No session available");
        }
        return this._tiSession;
    }

    private static _kimClient?: Promise<KiMClient>;
    public static get kimClient(): Promise<KiMClient> {
        if (!this._kimClient) {
            this._kimClient = this.tiSession.kimService.createKimClient().catch((e) => {
                this._kimClient = undefined;
                throw e;
            });
        }
        return this._kimClient;
    }


    @state()
    mainView!: HTMLElement | TemplateResult;
    recordIdentifier?: RecordIdentifier;

    @query("druide-dialog")
    druideDialog!: DruideDialog;

    connectedCallback() {

        Ti365.init(Global.gatewayUrl);
        void BrandingSettings.initBrandingSettings(Global.gatewayUrl);

        super.connectedCallback();
        window.addEventListener("popstate", (e) => this.stateChange());
        window.addEventListener("login", () => this.onLogin());
        window.addEventListener("logout", () => this.stateChange());
        window.addEventListener("smbc-unlocked", () => this.stateChange());
        window.addEventListener("record-identifier", (e) => (this.recordIdentifier = (e as CustomEvent<RecordIdentifier>).detail));
        window.addEventListener("smbc-locked", () => this.stateChange());
        window.document.addEventListener("visibilitychange", () => this.visibilityChange());
        this.visibilityChange()
            .then(() => this.stateChange());

    }

    async visibilityChange(): Promise<any> {

        if (document.visibilityState === "hidden" && TiPortal._tiSession) {
            const settings = await TiPortal._tiSession.getRuntimeSettings();
            if (settings) {
                const settingsString = JsonSerializer.serialize(settings);
                sessionStorage.setItem("ti365-session-settings", settingsString);
            }
        }

        if (document.visibilityState === "visible"
            && TiPortal._tiSession === undefined) {

            const settingsString = sessionStorage.getItem("ti365-session-settings")
            sessionStorage.removeItem("ti365-session-settings");

            if (settingsString !== null) {
                const settings = JsonSerializer.deserialize(settingsString) as Ti365SessionRuntimeSettings;
                TiPortal._tiSession = await Ti365Session.createWithKey(Ti365.get().getGatewayClient(), settings);
                StatusEvent.login();
                this.stateChange();
            }


        }

    }

    private onLogin() {
        this.initSessionListener();
        this.stateChange();
    }

    private initSessionListener() {
        const windowFetch = window.fetch;
        window.fetch = async (input: RequestInfo | URL, init?: RequestInit | undefined) => {
            const response = await windowFetch(input, init);
            if (response.status === 401) {
                window.dispatchEvent(new CustomEvent("session-timeout"));
            }
            return response;
        };
        window.addEventListener("session-timeout", () => this.showSessionTimeoutDialog());
    }

    private showSessionTimeoutDialog() {
        this.druideDialog.show();
    }

    stateChange(): void {
        const paths = this.getPathParts();
        if (paths[0] === "logout") {
            this.logout();
        } else if (paths[0]?.startsWith("jwt")) {
            this.handleJwt();
        } else if (BackendState.connection === "logout") {
            history.replaceState({}, "", "#");
            this.mainView = new LoginView();
        } else if (BackendState.connection === "ready") {
            if (BackendState.ti === "card-locked" || BackendState.ti === "unknown") {
                this.mainView = new TiConnectDialog();
            } else {
                const newView = this.routeLoggedInRequest();
                if (newView) {
                    this.mainView = newView;
                }
            }
        }
        this.requestUpdate();
    }

    private getPathParts() {
        const matches = location.hash.matchAll(/[#\/]([^\/]+)/gi);
        const pathParts: string[] = [];
        if (matches) {
            for (const match of matches) {
                pathParts.push(match[1]);
            }
        }
        return pathParts;
    }

    async handleJwt() {
        const jwtTokenMatch = /#jwt=(.+)/gi.exec(location.hash);
        if (!jwtTokenMatch) {
            this.showError("Ungültiger Aufruf des Login.");
            return;
        }

        const jwtString = jwtTokenMatch[1];
        const onboardingView = new Onboarding(jwtString);
        this.mainView = onboardingView;
    }

    private routeLoggedInRequest() {
        const pathPart = this.getPathParts();
        if (!pathPart[0]) {
            // No path, goto app selection
            history.pushState({}, "", "#ti-service-select");
            return new TiServiceSelect();
        } else {
            if (pathPart[0] === "ti-service-select") {
                return new TiServiceSelect();
            } else if (pathPart[0] === "kim") {
                if (!(this.mainView instanceof KimOverview)) {
                    return new KimOverview();
                }
            } else if (pathPart[0] === "kim-deregister") {
                if (!(this.mainView instanceof KimDeregisterSuccess)) {
                    return new KimDeregisterSuccess();
                }
            } else if (pathPart[0] === "kim-setup") {
                if (!(this.mainView instanceof KimSetupWizardView)) {
                    return new KimSetupWizardView();
                }
            } else if (pathPart[0] === "epa-select") {
                return new EpaSelectView(pathPart[1]);
            } else if (pathPart[0] === "egk-info") {
                return new EgkInfoView();
            } else if (pathPart[0] === "vzd") {
                return new VzdClientView();
            }

            if (pathPart[0] === "epa") {
                if (!this.recordIdentifier) {
                    // can not call epa without a id
                    location.hash = "#epa-select";
                    return undefined;
                }
                if (!(this.mainView instanceof EpaOverview)) {
                    return new EpaOverview(this.recordIdentifier);
                }
            }
        }
    }

    protected showError(msg: string) {
        this.mainView = html`      
            <div slot="content" style=${CssConst.flexCenter} >
                <druide-card slot="content" style="max-width:400px;min-width:300px;" label="Fehler">                
                    <div style="display:flex; flex-direction:column">
                        <div>
                            ${msg}
                        </div>
                    </div>
                    <druide-button slot="footer-left" title="Besuche Miya" @click=${() => (location.href = "https://miya.de")}   ></druide-button>
                    <druide-button slot="footer-right" title="Zum Login" @click=${() => (location.hash = "")}   ></druide-button>
                </druide-card>
            </div>`;
    }

    protected override render(): TemplateResult {
        return html`
            ${this.mainView}
            ${this.renderSessionTimeoutDialog()}
        `;
    }

    private renderSessionTimeoutDialog() {
        return html`
            <druide-dialog>
                <h3>Automatisch Abgemeldet</h3>
                Sie wurden automatisch abgemeldet, da die Verbindung getrennt wurde.
                <div class="dialog-buttons">
                    <druide-button @click=${() => this.logout()}>Zurück zur Anmeldung</druide-button>
                </div>
            </druide-dialog>`;
    }

    private logout() {
        TiPortal._tiSession = undefined;
        sessionStorage.removeItem("ti365-session-settings");
        history.replaceState({}, "", "#");
        this.mainView = new LoginView();
        this.druideDialog.hide();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "ti-portal": TiPortal;
    }
}
