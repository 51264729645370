import { KimMail, MailAddressType, MailHeader } from "@yoshteq/ti365-ts-sdk";
import { KimNewMail, MailReceiver } from "./KimNewMail";
import DOMPurify from "dompurify";

export class KimNewMailReply {


    public static createView(replyToMail: KimMail) {

        const newMail = new KimNewMail();
        newMail.title = "Antwort auf KIM Nachricht";
        newMail.mailBody = this.createMailBody(replyToMail);

        newMail.receivers.push({ name: replyToMail.from.name, mail: replyToMail.from.address } as MailReceiver);
        newMail.headers.push(new MailHeader("In-Reply-To", replyToMail.messageId));
        newMail.subject = replyToMail.subject.toLowerCase().startsWith("aw:")
            || replyToMail.subject.toLowerCase().startsWith("re:")
            ? replyToMail.subject : "AW: "
            + replyToMail.subject;
        return newMail;
    }

    private static createMailBody(replyToMail: KimMail) {

        let quote = `
                <br>
                <hr>
                <blockquote>
                <div>
                    <b>Von:</b>
                    <span>${this.printMailAddress(replyToMail.from)}</span>
                </div>
                <div>
                    <b>Gesendet:</b>
                    <span>${replyToMail.sentDate.toLocaleString()}</span>
                </div>
                <div>
                    <b>An:</b>
                    <span>${replyToMail.recipientsTo?.map((r) => this.printMailAddress(r)).join(", ")}</span>
                </div>`;

        if (replyToMail.recipientsCc && replyToMail.recipientsCc?.length > 0) {
            quote += `<div>
                    <b>CC:</b>
                    <span>${replyToMail.recipientsCc?.map((r) => this.printMailAddress(r)).join(", ")}</span>
                    </div>`;
        }

        quote += `<div>
                        <b>Betreff:</b>
                        <span>${replyToMail.subject}</span>
                      </div>
                      ${DOMPurify.sanitize(replyToMail.body)}</blockquote>
                    `;
        return quote;

        return "";
    }
    private static printMailAddress(mail: MailAddressType) {
        if (mail.name) {
            return `${mail.name} &lt;${mail.address}&gt;`;
        }
        return mail.address;
    }
}