import { svgDelete } from "@/Components/SvgConst";
import "@tinymce/tinymce-webcomponent";
import { DruideModal, DruideTextField } from "@yoshteq/druide-webcomponents";
import {
    KiMClient
} from "@yoshteq/ti365-ts-sdk";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { KimMailTemplateService } from "./KimMailTemplateService";
import { KimMailTemplateType } from "./KimMailTemplateType";


@customElement("kim-template-edit-view")
export class KimTemplateEditView extends LitElement {

    public static readonly PATH_PREFIX = "#kim/template/"
    static styles = [css`
        .main-layout{
            display: flex;
            flex-direction: column;
        }
        .main-layout>druide-text-field,
        .main-layout>druide-autocomplete-field {
            padding-top: 0;
        }
        `];

    @query("#content-input")
    contentInput!: { value: string } & HTMLElement;
    @query("#name-input")
    nameInput!: DruideTextField;
    @query("#description-input")
    descriptionInput!: DruideTextField;
    @query("#subject-input")
    subjectInput!: DruideTextField;

    @query("#deleteDialog")
    deleteDialog!: DruideModal;

    @property()
    title: string = "Neue KIM Vorlage";

    private template: KimMailTemplateType = {
        id: undefined,
        internal: false,
        userDefined: true,
        name: "",
        description: "",
        subject: "",
        body: ""
    }

    constructor(private kimClient: KiMClient) {
        super();
    }

    public static navigateTo(id: String | undefined) {
        location.hash = this.PATH_PREFIX + (id === undefined ? "new" : id);
    }

    public static async createFromUrl(kimClient: KiMClient): Promise<KimTemplateEditView> {
        const pathParts = location.hash.split("/");
        const templateId = pathParts[pathParts.length - 1];

        const view = new KimTemplateEditView(kimClient);
        const templateService = view.getTemplateService();
        const template = await templateService.find(templateId);
        if (template) {

            view.template = { ...template };
            if (template.userDefined) {
                view.title = "KIM Vorlage bearbeiten"
            } else {
                view.title = "KIM Vorlage duplizieren"
                view.template.id = undefined
            }
        }
        return view;
    }

    private templateService: KimMailTemplateService | undefined;
    private getTemplateService(): KimMailTemplateService {
        if (this.templateService === undefined) {
            const client = this.kimClient;
            const storage = client.getObjectStorage();
            this.templateService = new KimMailTemplateService(storage);
        }
        return this.templateService;
    }

    private async saveTemplate() {
        if (this.nameInput.value.length === 0) {
            this.nameInput.error = "Bitte einen Namen angeben"
            return;
        } else {
            this.nameInput.error = undefined;
        }
        this.template.internal = false;
        this.template.userDefined = true;
        this.template.name = this.nameInput.value;
        this.template.description = this.descriptionInput.value;
        this.template.subject = this.subjectInput.value;
        this.template.body = this.contentInput.value;

        const templateService = await this.getTemplateService();
        await templateService.saveTemplate(this.template);
        location.hash = "#kim/new";
    }


    private delete() {
        this.deleteDialog.show()
    }
    private async deleteConfirm() {
        await this.getTemplateService().deleteTemplate(this.template.id);
        location.hash = "#kim/new";
    }

    protected override render(): TemplateResult {
        const deleteButton = this.template.id
            ? html`<druide-icon-button @click=${this.delete}>${svgDelete}</druide-icon-button>`
            : html``;

        return html`
            <druide-card label=${this.title} @submit=${this.saveTemplate}>
                <div slot="header-action">${deleteButton}</div>
                <div class="main-layout">
                    <druide-text-field id="name-input" label="Bezeichnung:" .value=${this.template.name} autofocus required></druide-text-field>
                    <druide-text-field id="description-input" label="Beschreibung:" .value=${this.template.description}></druide-text-field>
                    <druide-text-field id="subject-input" label="Betreff:" .value=${this.template.subject}></druide-text-field>
                    ${this.buildEditor()}
                </div>
                <druide-button slot="footer-right" submit>Speichern</druide-button>
            </druide-card>
            <druide-modal id="deleteDialog" close-explicit>       
                <druide-card >
                <p>
                    Möchten Sie diese KIM Vorlage wirklich löschen?
                </p>
                <druide-button slot="footer-left"  @click=${() => this.deleteDialog.hide()}>Nein</druide-button> 
                <druide-button slot="footer-right" @click=${() => this.deleteConfirm()}>Löschen</druide-button> 
            </druide-card>
        </druide-modal>
        `;
    }

    private buildEditor(): TemplateResult {
        return html`
            <tinymce-editor id="content-input"
            plugins="preview importcss searchreplace autolink visualblocks visualchars fullscreen image link media table charmap nonbreaking anchor insertdatetime advlist lists charmap quickbars emoticons accordion"
            toolbar="undo redo | bold italic underline strikethrough | blocks | alignleft aligncenter alignright alignjustify | outdent indent | fontfamily fontsize | numlist bullist accordion | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview | insertfile image media template link anchor codesample"
            menubar="edit insert view format table tools"
            removed_menuitems="styles"
                promotion="false"
                language_url="/de.js"
                language="de">
                ${this.template.body}
            </tinymce-editor>
            `;
    }



}
