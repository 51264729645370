import { BrandingSettings } from "@/BrandingSettings";
import { CacheValue } from "@/Util/CacheValue";
import { format } from 'date-fns';
import { KimMailTemplateType } from "./KimMailTemplateType";
import { KimObjectStorage } from "@yoshteq/ti365-ts-sdk";

export class KimMailTemplateService {

    static readonly TEMPLATE_PATH = "kim_templates.json";

    private templates = new CacheValue<KimMailTemplateType[]>(10 * 1000);

    constructor(private kimStorage: KimObjectStorage) {

    }

    private async loadUserTemplates() {
        let templates = await this.kimStorage.readObject<KimMailTemplateType[]>(KimMailTemplateService.TEMPLATE_PATH);
        if (templates === undefined) {
            templates = []
            // there are no user templates jet, initialize an Empty list.
            await this.kimStorage.storeObject(KimMailTemplateService.TEMPLATE_PATH, templates)
        }
        return templates;
    }

    private async loadTemplates() {
        const templates = await this.loadUserTemplates();
        BrandingSettings.get().kimTemplates?.forEach(t => {
            t.userDefined = false;
            templates!.push(t);

        });
        return templates;
    }

    public async deleteTemplate(id: string | undefined) {
        let userTemplates = await this.loadUserTemplates();
        userTemplates = userTemplates.filter(t => t.id !== id);
        await this.kimStorage.storeObject(KimMailTemplateService.TEMPLATE_PATH, userTemplates)
        this.templates.invalidateCache();
    }


    public async saveTemplate(template: KimMailTemplateType) {

        const userTemplates = await this.loadUserTemplates();

        if (template.id === undefined || template.id === "") {
            template.id = crypto.randomUUID();
        }

        const idx = userTemplates.findIndex(t => t.id === template.id);
        if (idx === -1) {
            userTemplates.push(template)
        } else {
            userTemplates[idx] = template;
        }
        await this.kimStorage.storeObject(KimMailTemplateService.TEMPLATE_PATH, userTemplates)
        this.templates.invalidateCache();
    }

    public async searchTemplates(search: string = "", showInternal: boolean = false): Promise<KimMailTemplateType[]> {
        const allTemplates = await this.templates.getOrLoadAsync(() => this.loadTemplates());
        search = search.toLocaleLowerCase();
        const filteredTemplates = allTemplates
            .filter(t => showInternal ? true : !t.internal)
            .filter(t => (t.name.toLocaleLowerCase().indexOf(search) > -1
                || t.description.toLocaleLowerCase().indexOf(search) > -1))
            .sort((o1, o2) => o1.name.localeCompare(o2.name));
        return filteredTemplates;
    }

    public async find(id: string): Promise<KimMailTemplateType | undefined> {
        const allTemplates = await this.templates.getOrLoadAsync(() => this.loadTemplates());
        const filteredTemplate = allTemplates.find(t => (t.id === id));
        return filteredTemplate;
    }


    /**
     * Replaces Variables within a template string. Variables are identified by being placed within two # chars.
     * @param templateString 
     * @param dictionary 
     * @returns 
     */
    public replaceVariables(templateString: string, dictionary: Map<string, string>): string {

        dictionary.set("datum", format(new Date(), "dd.MM.yyyy"));
        dictionary.set("uhrzeit", format(new Date(), "HH:mm"));
        dictionary.forEach((value, key) => {
            templateString = templateString.replaceAll("#" + key + "#", value);
        });
        return templateString;
    }

}