import { svgKimTemplate } from "@/Components/SvgConst";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { KimMailTemplateType } from "./KimMailTemplateType";

@customElement("kim-template-list-entry")
export class KimTemplateListEntry extends LitElement {
    static styles = css`
    :host{
        cursor:default;
    }`;

    constructor(template: KimMailTemplateType) {
        super();
        this.template = template;
    }

    @property({ type: Object, reflect: false })
    public template: KimMailTemplateType;

    protected override render(): TemplateResult {
        if (!this.template) {
            return html``;
        }
        return html`   <span style="display:flex;flex-direction:row;gap:0.5em;">${svgKimTemplate}${this.template.name}</span>
        <div style="font-size: 0.8em; opacity: 0.6;">${this.template.description}</div>`;
    }
}
