import { DruideModal } from "@yoshteq/druide-webcomponents";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { svgLogout } from "./SvgConst";
import { portalName } from "@/BrandingSettings";

@customElement("logout-button")
export default class LogoutButton extends LitElement {
    static styles = css`
    :host{
        font-size:1rem;
        text-align:left;
    }
    `;

    @property()
    title: string = "";

    @property()
    content: string | TemplateResult = "";

    @query("druide-modal")
    modal!: DruideModal;

    protected render(): TemplateResult {
        return html`
        <druide-icon-button id="app-button" class="back-header" @click=${() => this.logout()}>${svgLogout}</druide-icon-button>
        <druide-modal>
            <druide-card label="Abmelden">
                <div>Möchten sie sich aus <b>${portalName}</b> abmelden?</div>
                <druide-button slot="footer-right" @click=${() => location.hash = "#logout"}>Ja</druide-button>
                <druide-button slot="footer-left" @click=${() => this.modal.hide()}>Nein</druide-button>
            </druide-card>
        </druide-modal>
        `;
    }

    public logout() {
        this.modal.show();
    }

    public show() {
        this.modal.show();
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "logout-button": LogoutButton;
    }
}