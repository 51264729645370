import { portalName } from "@/BrandingSettings";
import { watch } from "@lit-labs/preact-signals";
import { DruideSelectField, DruideTextField } from "@yoshteq/druide-webcomponents";
import { Profession, VzdEntry, VzdSearchQuery } from "@yoshteq/ti365-ts-sdk";
import { LitElement, TemplateResult, html, unsafeCSS } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { CssConst } from "../Components/CssConst";
import DruideDialog from "../Components/DruideDialog";
import { StatusEventController } from "../Components/StatusEventController";
import { svgBackArrow, svgClose } from "../Components/SvgConst";
import { TiPortal } from "../TiPortal";
import style from "./VzdClientView.css?inline";
import "./VzdListEntry";

@customElement("vzd-client-view")
export class VzdClientView extends LitElement {
    static styles = [unsafeCSS(style)];

    status = new StatusEventController(this);

    @query("#displayName")
    displayName!: DruideTextField;

    @query("#mail")
    mail!: DruideTextField;

    @query("#profession")
    profession!: DruideSelectField;

    @query("druide-dialog")
    extendedSearchDialog!: DruideDialog;

    @state()
    readPending = false;

    @state()
    searchResult: VzdEntry[] = [];

    protected override render(): TemplateResult {
        return html`
            <header-layout>
                <div slot="header-left"><druide-icon-button id="app-button" class="back-header" @click=${() => history.back()}>${svgBackArrow}</druide-icon-button></div>
                <div slot="header-center">${watch(portalName)}</div>
                <div slot="content" .style=${CssConst.flexCenter}>
                    <div style="display: flex; flex-direction: column; max-width: 100%">
                        <druide-card slot="content" style="max-width:800px;" label="Verzeichnisdienst">
                            <druide-text-field id="displayName" label="Name" style="width: 100%"></druide-text-field>
                            <druide-text-field id="mail" label="KIM-Adresse" style="width: 100%"></druide-text-field>
                            <druide-select-field id="profession" label="Beruf" style="width: 100%" .options=${this.buildProfessionOptions()} >
                                <option></option>
                            </druide-select-field ><br><br>
                            <div slot="footer-left">${this.status.message}</div>
                            <druide-button slot="footer-left" title="Erweiterte Suche" @click=${this.openExtendedSearchDialog} style="flex-grow: 0"></druide-button>
                            <druide-button slot="footer-right" title="Suchen" @click=${this.readCard} .disabled=${this.readPending} ></druide-button>
                        </druide-card>
    
                        <druide-card style=${this.searchResult.length > 0 || this.readPending ? "" : "display: none"}>
                            <div style="display:flex; flex-direction:column; align-items: center">
                                ${this.readPending ? this.renderLoadingScreen() : this.renderSearchResults()}
                            </div>
                        </druide-card>
                    </div>
                    ${this.renderExtendedSearchDialog()}
                </div>
            </header-layout>`;
    }

    private buildProfessionOptions() {
        return Profession.HBA_PROFESSIONS.map(p => {
            return { id: p.oid, title: p.description! };
        });
    }

    private renderExtendedSearchDialog() {
        return html`
            <druide-dialog>
                <div style="display: flex; justify-content: space-between">
                    <h3>Allgemeine Informationen</h3>
                    <druide-icon-button .icon=${svgClose} @click=${this.closeExtendedSearchDialog}></druide-icon-button>
                </div>
                <div style="width: 100%; display: flex">
                    <druide-text-field id="ext-title" label="Titel" style="flex-grow: 1;min-width: 0;"></druide-text-field>
                    <druide-text-field id="ext-name" label="Name" style="flex-grow: 3;min-width: 0;"></druide-text-field>
                </div>
                <div style="width: 100%; display: flex">
                    <druide-text-field id="ext-organization" label="Firma" style="flex-grow: 1;min-width: 0;"></druide-text-field>
                    <druide-select-field id="ext-profession" label="Beruf" style="flex-grow: 1; max-width: 50%;min-width: 0;" .options=${this.buildProfessionOptions()}></druide-select-field>
                </div>
                <druide-text-field id="ext-mail" label="E-Mail" style="width: 100%"></druide-text-field>
                <h3>Adressinformationen</h3>
                <druide-text-field id="ext-streetAddress" label="Straße & Hausnummer" style="width: 100%"></druide-text-field>
                <div style="width: 100%; display: flex">
                    <druide-text-field id="ext-postalCode" label="Postleitzahl" style="flex-grow: 1;min-width: 0;"></druide-text-field>
                    <druide-text-field id="ext-localityName" label="Ort" style="flex-grow: 1;min-width: 0;"></druide-text-field>
                </div>
                <div style="width: 100%; display: flex">
                    <druide-select-field id="ext-stateOrProvinceName" label="Bundesland" style="flex-grow: 1;min-width: 0;" .options=${this.buildStateOrProvinzeOptions()}></druide-select-field>
                    <druide-select-field id="ext-countryCode" label="Land" style="flex-grow: 1;min-width: 0;" .options=${this.buildCountryOptions()}></druide-select-field>
                </div>
                <druide-button title="Suchen" @click=${this.searchExtended}></druide-button>
            </druide-dialog>
        `;
    }

    private buildStateOrProvinzeOptions() {
        return [
            "Berlin",
            "Brandenburg",
            "Bremen",
            "Hamburg",
            "Hessen",
            "Mecklenburg-Vorpommern",
            "Niedersachsen",
            "Nordrhein-Westfalen",
            "Rheinland-Pfalz",
            "Saarland",
            "Sachsen",
            "Sachsen-Anhalt",
            "Schleswig-Holstein",
            "Thüringen",
            "Nordrhein",
            "Westfalen-Lippe"
        ].map(c => {
            return { id: c, title: c };
        });
    }

    private buildCountryOptions() {
        return [
            { country: "Deutschland", code: "de" },
            { country: "Frankreich", code: "fr" },
            { country: "Schweiz", code: "ch" },
            { country: "Österreich", code: "at" },
            { country: "Tschechien", code: "cz" },
            { country: "Polen", code: "pl" },
            { country: "Dänemark", code: "dk" },
            { country: "Niederlande", code: "nl" },
            { country: "Belgien", code: "be" },
            { country: "Luxemburg", code: "lu" },
            { country: "Andere", code: "" }
        ].map(c => {
            return { id: c.code, title: c.country };
        });
    }

    private openExtendedSearchDialog() {
        this.extendedSearchDialog.querySelector<DruideTextField>("#ext-name")!.value = this.displayName.value;
        this.extendedSearchDialog.querySelector<DruideTextField>("#ext-mail")!.value = this.mail.value;
        this.extendedSearchDialog.querySelector<DruideSelectField>("#ext-profession")!.value = this.profession.value;

        this.extendedSearchDialog.show();
    }

    private closeExtendedSearchDialog() {
        this.displayName.value = this.extendedSearchDialog.querySelector<DruideTextField>("#ext-name")!.value;
        this.mail.value = this.extendedSearchDialog.querySelector<DruideTextField>("#ext-mail")!.value;
        this.profession.value = this.extendedSearchDialog.querySelector<DruideSelectField>("#ext-profession")!.value;

        this.extendedSearchDialog.hide();
    }

    private renderLoadingScreen() {
        return html`
            <div style="display: flex; flex-direction: column; align-items: center">
                <loader-spinner  style="align-self:center"></loader-spinner>
                <div>Vzd Einträge werden geladen ...</div>
            </div>
        `;
    }

    private renderSearchResults() {
        return !this.searchResult ? "" : this.searchResult.map(e => html`
            <vzd-list-entry .vzdEntry=${e}></vzd-list-entry>
        `);
    }

    protected async readCard() {
        this.readPending = true;
        this.searchResult = await TiPortal.tiSession.vzdService.search({ displayName: VzdClientView.getWildcarded(this.displayName.value), mail: VzdClientView.getWildcarded(this.mail.value), profession: this.getProfessionForId(this.getSingleValueOfSelectField(this.profession.value)) });
        this.readPending = false;
    }

    private getProfessionForId(id: string) {
        return Profession.HBA_PROFESSIONS.find(p => p.oid === id);
    }

    private async searchExtended() {
        this.readPending = true;

        const searchQuery: VzdSearchQuery = {
            title: this.getValueAndAddWildcard("#ext-title"),
            displayName: this.getValueAndAddWildcard("#ext-name"),
            organization: this.getValueAndAddWildcard("#ext-organization"),
            profession: this.getProfessionForId(this.getSingleValueOfSelectField((this.extendedSearchDialog.querySelector<DruideSelectField>("#ext-profession")!.value))),
            mail: this.getValueAndAddWildcard("#ext-mail"),
            streetAddress: this.getValueAndAddWildcard("#ext-streetAddress"),
            postalCode: this.getValueAndAddWildcard("#ext-postalCode"),
            localityName: this.getValueAndAddWildcard("#ext-localityName"),
            stateOrProvinceName: this.getSingleValueOfSelectField(this.extendedSearchDialog.querySelector<DruideSelectField>("#ext-stateOrProvinceName")!.value),
            countryCode: this.getSingleValueOfSelectField(this.extendedSearchDialog.querySelector<DruideSelectField>("#ext-countryCode")!.value)
        };

        this.searchResult = await TiPortal.tiSession.vzdService.search(searchQuery);

        this.displayName.value = this.extendedSearchDialog.querySelector<DruideTextField>("#ext-name")!.value;
        this.mail.value = this.extendedSearchDialog.querySelector<DruideTextField>("#ext-mail")!.value;
        this.profession.value = this.extendedSearchDialog.querySelector<DruideSelectField>("#ext-profession")!.value;

        this.extendedSearchDialog.hide();

        this.readPending = false;
    }

    private getValueAndAddWildcard(fieldId: string) {
        return VzdClientView.getWildcarded(this.extendedSearchDialog.querySelector<DruideTextField>(fieldId)!.value);
    }

    private getSingleValueOfSelectField(value: string | string[]) {
        return typeof value === "string" ? value : value[0];
    }

    private static getWildcarded(value?: string): string | undefined {

        if (value) {
            if (value === "*") {
                return value;
            }

            return "*" + value + "*";
        }

        return undefined;
    }

}
