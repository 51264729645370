import { watch } from "@lit-labs/preact-signals";
import { Card, CardType, ConnectionStatus } from "@yoshteq/ti365-ts-sdk";
import { LitElement, TemplateResult, html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { portalName } from "./BrandingSettings";
import { CssConst } from "./Components/CssConst";
import { EnterPinDialog } from "./EnterPinDialog";
import { TiPortal } from "./TiPortal";

@customElement("ti-connect-dialog")
export class TiConnectDialog extends LitElement {

    @state()
    private message = "Verbindung zur TI wird hergestellt";

    @state()
    card?: Card;

    private pinDialog?: EnterPinDialog;

    @state()
    isRetryPossible = false;

    connectedCallback(): void {
        super.connectedCallback();
        this.connectToTi();
    }

    private async connectToTi() {
        try {
            this.isRetryPossible = false;
            const connectorStatus = await TiPortal.tiSession.statusService.getConnectorStatusInformation();
            if (connectorStatus.vpnTiStatus.connectionStatus !== ConnectionStatus.ONLINE) {
                this.message = "Der Konnektor ist aktuell nicht mit der TI verbunden.";
                return;
            }
            await this.searchSmcB();
        } catch (e) {
            console.error(e);
            this.message = "Unbekannter Fehler beim Verbinden zur TI.";
        }
    }

    private async searchSmcB(shouldTryAssignSmcb = true) {
        this.card = await TiPortal.tiSession.cardService.getCard(CardType.SMC_B, true);
        if (!this.card) {
            if (shouldTryAssignSmcb) {
                this.message = "Es wird versucht die SMC-B automatisch zuzuweisen.";
                await this.tryAutoAssignSmcb();
            } else {
                this.message = "Es wurde keine SMC-B gefunden.";
            }
            return;
        }
    }

    private async tryAutoAssignSmcb() {
        const result = await TiPortal.tiSession.configService.tryAutoAssignSmcb();
        switch (result) {
            case "INVALID_CONFIGURATION":
                this.message = "Die Konfiguration Ihres TI-Zugangs ist fehlerhaft. Bitte wenden Sie sich an Ihren Support.";
                break;
            case "SMCB_ALREADY_ASSIGNED":
                this.message = "Die SMC-B ist bereits zugeordnet.";
                this.isRetryPossible = true;
                break;
            case "NO_CARD_TERMINAL_CONNECTED":
                this.message = "Es ist kein Kartenterminal mit dem Konnektor verbunden. Bitte schalten Sie das Gerät ein und warten Sie, bis es mit dem VPN verbunden ist.";
                this.isRetryPossible = true;
                break;
            case "NO_SMCB_FOUND":
                this.message = "Es wurde keine SMC-B im Kartenterminal gefunden. Bitte setzen Sie die SMC-B wie in der Anleitung beschrieben in das Kartenterminal ein.";
                this.isRetryPossible = true;
                break;
            case "MULTIPLE_SMCB_FOUND":
                this.message = "Es wurde mehr als eine SMC-B gefunden. Bitte stellen Sie sicher, dass nur eine SMC-B in den verbundenen Kartenterminals eingesetzt ist.";
                this.isRetryPossible = true;
                break;
            case "ERROR":
                this.message = "Es ist ein unbekannter Fehler aufgetreten. Bitte wenden Sie sich an Ihren Support.";
                break;
            case "SUCCESS":
                this.message = "Die SMC-B wurde erfolgreich zugewiesen.";
                await this.searchSmcB(false);
                break;
        }
    }

    protected override render(): TemplateResult {
        return html`
        <header-layout>
            <div slot="header-center">${watch(portalName)}</div>
            <div slot="content" .style=${CssConst.flexCenter}>
                <druide-card style="width:360px;" label="Verbindung Telematik Konnektor">
                    ${this.renderContent()}
                </druide-card>
            </div>
        </header-layout>`;
    }

    protected renderContent() {
        if (this.card && !this.pinDialog) {
            this.pinDialog = new EnterPinDialog();
            this.pinDialog.card = this.card;
        }
        return html`
            <div style="text-align: center">
                <loader-message>${this.message}</loader-message><br>
                ${this.isRetryPossible ? html`<druide-button @click=${this.connectToTi}>Erneut versuchen</druide-button>` : ""}
                ${this.pinDialog ?? undefined}
            </div>
        `;
    }
}
