import { signal } from "@lit-labs/preact-signals";
import { KimMailTemplateType } from "./Kim/Template/KimMailTemplateType";

export type BrandingSettingsType = {
    portalName: string;
    kimTemplates: KimMailTemplateType[] | undefined;
};

export const portalName = signal("Ti365");

export class BrandingSettings {

    private static settings: BrandingSettingsType = {
        portalName: "Ti365",
        kimTemplates: [],
    };

    public static async initBrandingSettings(baseUrl: string): Promise<void> {
        try {
            this.settings = await fetch(baseUrl + "/branding/settings").then(r => r.json()) as BrandingSettingsType;
            portalName.value = this.settings.portalName;
        } catch (e) {
            console.error("Branding Settings could not be loaded", e)
        }
    }

    public static get(): BrandingSettingsType {
        return this.settings;
    }



}

